import * as React from 'react';
import { BladeButton, BladeIcon, BladeLoader } from '@aventus/blade';
import styles from './css/styles.css';
import { AdditionalComponentTieredPrices, IntelligentQuoteQuestionPackageInclusion, IntelligentQuoteQuestionPackageOption } from '@aventus/platform';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { ITieredPackage } from '@aventus/blade/input/package-selector/use-package-selector';

interface RoadsideTierSelectorProps {
  packageInclusions: IntelligentQuoteQuestionPackageInclusion[]
  packageOptions?: IntelligentQuoteQuestionPackageOption[];
  value: ITieredPackage;
  onPackageSelected: (isSelected: boolean, level: Nullable<string>) => void;
  canBeRemoved: boolean;
  packageWasInteractedWith: boolean;
  packageIsBeingAdded: boolean;
  packageIsBeingRemoved: boolean;
  childComponentUpdated?: boolean;
  tieredPackagePrices?: AdditionalComponentTieredPrices;
}

const RoadsideTierSelector = (props: RoadsideTierSelectorProps) => {
  const [packagedIsBeingToggledOn, setPackageIsBeingToggledOn] = React.useState(false);
  const [packagedIsBeingToggledOff, setPackageIsBeingToggledOff] = React.useState(false);

  React.useEffect(() => {
    if (packagedIsBeingToggledOn && !props.packageIsBeingAdded) {
      setPackageIsBeingToggledOn(false);
    }

    if (packagedIsBeingToggledOff && !props.packageIsBeingRemoved) {
      setPackageIsBeingToggledOff(false);
    }
  }, [props.packageIsBeingAdded, props.packageIsBeingRemoved]);

  const handlePackageSelection = (isSelected: boolean, level: Nullable<string>) => {

    if(packagedIsBeingToggledOn || packagedIsBeingToggledOff){
      return;
    }

    if (isSelected) {
      setPackageIsBeingToggledOn(true);
    } else {
      setPackageIsBeingToggledOff(true);
    }
    props.onPackageSelected(isSelected, level);
  };
/*
  const packageIsSelectable = (level: string) => {
    if (packagedIsBeingToggledOn) {
      return true;
    } else if (packagedIsBeingToggledOff) {
      return false;
    } else if (!props.value.selected) {
      return true;
    } else {
      return props.value.level !== level; // if this tier isn't selected
    }
  };

  const isLoading = (level: string) => {
    const isSelected = props.value.selected && props.value.level === level;
    return (isSelected && packagedIsBeingToggledOff) ||
           (!isSelected && packagedIsBeingToggledOn) ||
           props.packageWasInteractedWith;
  };
*/
  const resolveTieredPackagePrice = (level: string) => {
    if (props.tieredPackagePrices == null){
      return null;
    }

    const price = props.tieredPackagePrices[level];
    if (price == null){
        return null;
    }

    let amount = price.value;

    if (props.value.selected === true && props.value.level !== level)
    {
        const selectedPrice = props.tieredPackagePrices[props.value.level!].value;

        const priceDifference = price.value - selectedPrice;

        if (priceDifference > 0) {
          let formattedPrice = currencyToHumanReadable({
            currencyCode: price.currencyCode,
            value: Math.abs(priceDifference)
          });

          return "Upgrade for just " + formattedPrice + " more"
        }
    }

    const formattedPrice = currencyToHumanReadable({
        currencyCode: price.currencyCode,
        value: Math.abs(amount)
    });

    return formattedPrice + " / year";
};

const getButtonText = (option: IntelligentQuoteQuestionPackageOption) => {
  const isSelected = props.value.selected && props.value.level === option.level;
  /*const isBeingSelected = packagedIsBeingToggledOn && !isSelected;
  const isBeingDeselected = packagedIsBeingToggledOff && isSelected;*/

  const isLoading = packagedIsBeingToggledOn || packagedIsBeingToggledOff;
  if(isLoading){
    return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <BladeLoader color="white" />
    </div>)
  }
  if (isSelected) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5em', width: '100%' }}>
        <BladeIcon color="white" name="check-circle" />
        <span>Selected</span>
      </div>
    );
  }else{
    return 'Select';
  }

};

  return (
    <div className={styles.tierContainer}>
      {props.packageOptions?.map((option: IntelligentQuoteQuestionPackageOption) => {
        const isSelected = props.value.selected && props.value.level === option.level;

        return (
          <div
            key={option.level}
            className={`${styles.tierColumn} ${isSelected ? styles.selectedTier : ''}`}
            onClick={() => handlePackageSelection(!isSelected, option.level)}
          >
            <div className={styles.tierHeader}>
              <h3>{option.header}</h3>
              <div className={styles.price}>{resolveTieredPackagePrice(option.level)}</div>
            </div>

            <div className={styles.inclusionsList}>
              {props.packageInclusions?.map((inclusion: IntelligentQuoteQuestionPackageInclusion) => (
                <div key={inclusion.name} className={styles.inclusionRow}>
                  <div className={styles.inclusionName}>{inclusion.name} 
                    {inclusion.tiers[option.level].extra && (
                      <span>&nbsp;{inclusion.tiers[option.level].extra}</span>
                    )}
                  </div>
                  <div className={styles.inclusionValue}>
                    {typeof inclusion.tiers[option.level].value === 'boolean' ? (
                      <BladeIcon
                        name={inclusion.tiers[option.level].value ? 'faCheck' : 'faTimes'}
                        className={inclusion.tiers[option.level].value ? styles.checkIcon : styles.crossIcon}
                      />
                    ) : (
                      <span>{inclusion.tiers[option.level].value}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
                        
              <div className={`${styles.packageButtons}`}>
                <BladeButton
                  className={styles.selectButton}
                  onClick={() => handlePackageSelection(!isSelected, option.level)}
                  isDisabled={props.packageWasInteractedWith}

                >
                  {getButtonText(option)}
                </BladeButton>

                {isSelected && props.canBeRemoved && (
                   <BladeButton
                   className={`${styles.removeFromQuoteButton} ${styles.mobileOnly}`}
                   variant="secondary"
                   isInline
                   onClick={() => handlePackageSelection(false, null)}
                   isDisabled={props.packageWasInteractedWith}
                 >
                   <BladeIcon name={'faTrashAlt'} />
                 </BladeButton>
                  )}
              </div>
           
          </div>
        );
      })}
    </div>
  );
};

export default RoadsideTierSelector;
