import * as React from 'react';

import { BladeIcon, BladeLink, BladeMarkdown, BladeModal, IBladeFormFieldset } from '@aventus/blade';
import {
  AdditionalComponentPrices,
  IntelligentQuoteQuestion
} from '@aventus/platform';

import { IBladeInputText } from '../../input/text';
import { EventType } from '@aventus/pocketknife/dispatch-event';
import styles from './css/styles.css';
import { ITieredPackage, usePackageSelector } from '@aventus/blade/input/package-selector/use-package-selector';
import RoadsideTierSelector from './roadside-tier-selector';

export const BladeFormFieldsetRoadsideAssistance: React.FunctionComponent<
  IBladeFormFieldsetRoadsideAssistance
> = props => {
  const [showMoreDetails, setShowMoreDetails] = React.useState<boolean>(false);
  const [childComponentWasUpdated, setChildComponentWasUpdated] = React.useState(false);

  const onPackageSelected = (isSelected: boolean, level: Nullable<string>) =>{
    
    let updated: ITieredPackage;
    if (isSelected){
        updated = { selected: true, level: level};
    }
    else{
        updated ={ selected: false, level: null};
    }

    handlePackageClick(updated);
  }

  const isSelected = props.value?.selected || false;

  const {
    canBeRemoved,
    packageIsBeingInteractedWith,
    packageIsBeingAdded,
    packageIsBeingRemoved,
    handlePackageClick,
    childComponentUpdated
  } = usePackageSelector(
    props.questionData,
    isSelected,
    props.selectedInOriginalRisk,
    props.isLoadingPrice,
    props.onChange,
    props.additionalComponentPrices,
    childComponentWasUpdated,
    setChildComponentWasUpdated
  );

  const handleChildComponentUpdated = (event: any) => {
    setChildComponentWasUpdated(props.name === event.detail.parentRef);
  };

  React.useEffect(() => {
    window.addEventListener(
      EventType.ChildComponentUpdated,
      handleChildComponentUpdated
    );

    return () => {
      window.removeEventListener(
        EventType.ChildComponentUpdated,
        handleChildComponentUpdated
      );
    };
  }, []);

  return (
    <>
    <div
      className={`${props.questionData.referenceID} ${styles.packageWrapper}${
        isSelected ? ` ${styles.selected}` : ''
      }`}
    >
      {isSelected && (
        <div className={styles.tickIconWrapper}>
          <BladeIcon name={'faCheck'} color="#FFF" />
        </div>
      )}

      <div className={styles.scaffold}>

        <div
          className={`${styles.titleWrapper} ${styles.headingCentered}`}
        >
          <div className={styles.packageIcon}></div>
          <legend className={styles.titleWrapper_questionBar_legend}>
                {props.question}
          </legend>

        </div>

        {props.description && (
          <BladeMarkdown
            markdown={props.description}
          />
        )}
        <RoadsideTierSelector
            packageInclusions={props.questionData.customProperties?.packageInclusions || []}
            packageOptions={props.questionData.customProperties?.packageOptions}
            canBeRemoved={canBeRemoved}
            onPackageSelected={onPackageSelected}
            packageWasInteractedWith={packageIsBeingInteractedWith}
            packageIsBeingAdded={packageIsBeingAdded}
            packageIsBeingRemoved={packageIsBeingRemoved}
            childComponentUpdated={childComponentUpdated}
            value={props.value}
            tieredPackagePrices={props.additionalComponentPrices?.[props.questionData.riskPath]?.tieredPackagePrices}
          />

          <div className={styles.packageFooter}>
            {props.moreDetails && (
              <BladeLink
                className={styles.moreInfo}
                onClick={() => setShowMoreDetails(true)}
              >* More details&nbsp; <BladeIcon name={'faChevronRight'} />
              </BladeLink>
            )}

            {isSelected && canBeRemoved && (
              <BladeLink
                  className={`${styles.removeFromQuoteLink} ${styles.desktopOnly}`}
                  onClick={() => onPackageSelected(false, null)}
              >
              <div className={styles.removeFromQuoteIcon}>
                  <BladeIcon name={'faTrashAlt'} />
              </div>
              &nbsp;Remove
              </BladeLink>
            )}

          </div>

        {showMoreDetails && (
          <BladeModal withFrame={true} close={() => setShowMoreDetails(false)}>
            <BladeMarkdown markdown={props.moreDetails} />
          </BladeModal>
        )}
      </div>
    </div>
  </>
  );
};

export interface IBladeFormFieldsetRoadsideAssistance
  extends IBladeFormFieldset,
  IBladeInputText {
  questionData: IntelligentQuoteQuestion;
  additionalComponentPrices?: AdditionalComponentPrices;
  selectedInOriginalRisk: boolean | null;
  isLoadingPrice: boolean;
}
