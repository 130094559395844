import * as React from 'react';
import {
  BladeFormFieldsetAddressInternational,
  BladeFormFieldsetName,
  BladeFormFieldsetAddress,
  BladeFormFieldsetMotor,
  BladeFormFieldset,
  BladeFormFieldsetTravelPackage,
  BladeInputDate,
  BladeInputDatePicker,
  BladeInputDateRange,
  BladeInputRadio,
  BladeInputNumber,
  BladeInputText,
  BladeInputSelect,
  BladeInputSegmented,
  BladeInputSwitch,
  BladeInputCollection,
  BladeInputMoney,
  BladeInputIncluded,
  BladeInputSlider,
  BladeClaims,
  BladeInputVoucher,
  BladeEmail,
  BladeTextBlock,
  BladeItemArrayFormGeneric,
  BladeFormFieldsetRoadsideAssistance
} from '@aventus/blade';
import { getFromForm } from '@aventus/formio';
import {
  IRisk,
  IntelligentQuoteQuestion,
  MtaInformation,
  PricingPlan,
  QuoteType
} from '@aventus/platform';
import { isDependencySatisfied } from './is-dependency-satisfied';
import {
  QuestionMissingConfiguration,
  ApplicationError
} from '@aventus/errors';
import {
  OraclesConfiguration,
  OrganisationConfiguration,
  InsuranceProductConfiguration
} from '@aventus/configuration';
import { stringReplacer } from '@aventus/pocketknife/string-replacer';
import { currencyToHumanReadable } from '@aventus/pocketknife/currency-to-human-readable';
import { objectMap } from '@aventus/pocketknife/object-map';
import { roundUp } from '@aventus/pocketknife/round-up';
import { runValidation } from '@aventus/pocketknife/validator/filter';
import countries from '../data/countries.json';
import {
  ToHumanReadable,
  ToPlatformReadable,
  ToApplicationReadable
} from '@aventus/application-timezone';

import { switchAddressOracle } from './switch-address-oracle';
import { switchMotorOracle } from './switch-motor-oracle';
import { BladeInputDateManualEntry } from '@aventus/blade/input/date-manual-entry';
import { builtInInputDateValidator } from '@aventus/pocketknife/validators/invalid-date';
import { mustBeValidPhoneNumberAU } from '@aventus/pocketknife/validators/must-be-valid-phone-number-au';
import { mustBeValidPhoneNumber } from '@aventus/pocketknife/validators/must-be-valid-phone-number';
import { motorWebMustHaveVehicleDetails } from '@aventus/pocketknife/validators/motorweb-must-have-vehicle-details';
import { BladeInputRangeSlider } from '@aventus/blade/input/range-slider';
import { roundDown } from '@aventus/pocketknife/round-down';
import { isValidDateRange } from '@aventus/pocketknife/validators/is-valid-date-range';
import { PanelLayout } from '@aventus/blade/form-fieldset';
import { IBladeItemArrayItem } from '@aventus/blade/input/item-array-form-generic';
import { v4 as uuidV4 } from 'uuid';
import { roadsideIsHiddenOracle } from './roadside-hidden-oracle';

export function mapQuestionMetaToQuestionFieldset(
  questions: IntelligentQuoteQuestion[],
  risk = {},
  originalRisk: IRisk,
  updateRisk: (
    riskAddress: string,
    value: any,
    isCollection: boolean,
    isValid?: boolean
  ) => void,
  validation: any,
  dispatchValidation: Function,
  settings: Settings,
  formatters: Formatters,
  orgConfiguration: OrganisationConfiguration,
  productConfiguration?: InsuranceProductConfiguration,
  riskState?: any,
  setRiskStateHash?: (value: string) => void,
  questionConfig?: {
    hideVoucher: boolean;
  },
  pricingPlan?: PricingPlan,
  mtaPricing?: MtaInformation,
  quoteId?: string,
  isLoadingPrice?: boolean,
  parentQuestion?: string,
  quoteType?: QuoteType
) {
  return (
    questions
      .map((question: IntelligentQuoteQuestion) => {
        // Prepare the value for this question.

        let _value = getFromForm(risk, question.riskPath);

        // If the question type is a collection, there could
        // be different behaviour when it comes to dealing with
        // the data, so we need to keep track of this.

        const isCollection =
          question.questionType === 'ItemArray' ||
          question.questionType === 'ItemArrayForm' ||
          question.questionType === 'SingleEntityBoxModal' ||
          question.questionType === 'SingleEntityModal' ||
          question.questionType === 'BoxModal' ||
          question.questionType === 'ItemArrayFormGeneric';

        // On the rare occassion where items within a generic collectiondo not already have an answerId set on them,
        // we need to add them. This usually happens when items are generated on the backend and passed to Symphony,
        // as ultimately, Symphony is responsible for assigning answerId's when items are manually added to a list by a user.
        if (
          question.questionType === 'ItemArrayFormGeneric' &&
          _value?.filter((x: IBladeItemArrayItem) => !x.answerId)?.length > 0
        ) {
          _value = _value.map((item: IBladeItemArrayItem) => ({
            ...item,
            answerId: item.answerId ? item.answerId : uuidV4()
          }));
        }

        // Before we do anything, let's check if the question
        // has any dependencies defined, and if it does then we'll
        // check if that dependency is satisfied. If it isn't then
        // we need to skip over this question.

        if (question.conditionRiskPath && question.conditionType) {
          const _isDependencySatisfied = isDependencySatisfied(
            question.conditionType,
            question.conditionValue,
            getFromForm(risk, question.conditionRiskPath)
          );

          if (!_isDependencySatisfied) {
            // Consider 3 questions, each dependent on the previous:
            // * A1
            // * A2 renders if A1 is true
            // * A3 renders if A2 is true
            // If the user fills out A1 as true, gets A2 and sets that to true, and lands on
            // A3 and fills that out, however decides to change A1 to false, then A2 is correctly
            // removed from view, however since A2's value hasn't been reset, A3 is still
            // visible because it's looking at A2's value.

            // If the dependency isn't satisified,
            // we can take a blanket approach at re-setting this
            // dependent question's value.
            // Make sure to reset with the expected reset value.

            if (question.questionType === 'ItemArray') {
              if (_value && Array.isArray(_value) && _value.length !== 0) {
                updateRisk(question.riskPath, [], isCollection);
              }
            } else {
              // Shit hack. See symphony client for more details (nextIntelligentQuote).
              // There's a similar hack in there.
              // The hack is only the question.referenceID check

              if (
                _value !== null &&
                question.referenceID !==
                  'hl_q_what_s_your_correspondence_address'
              ) {
                updateRisk(question.riskPath, null, isCollection);
              }
            }

            // If the dependency isn't satisfied then we don't want to
            // process and render this question, so we can just return
            // null. A filter at the bottom of this function will take
            // care of cleaning up any nulls in the mapped question array.

            return null;
          }
        }

        // If we got this far then this question is going to be
        // rendered. Our forms are composed of Fieldsets, each with one
        // or more inputs.
        // Knowing this we can prepare the common props for each of
        // these layers and spread them on the relevant components below.

        const commonFieldsetProps = {
          name: question.referenceID,
          question: question.text.questionText,
          description: question.text.summaryText,
          help: question.text.helpText,
          additionalText: question.text.additionalQuestionDescription,
          detail: question.text.detail,
          moreDetails: question.text.detailText,
          icon: settings.questionIcons?.[question.referenceID],
          image: settings.questionImages?.[question.referenceID],
          customProperties: question.customProperties,
          key: question.referenceID,
          tooltip: question.text.tooltip
        };

        // Note, that there might be certain bespoke handling needed
        // based on the data type. For example dates need to be formatted
        // based on application assumptions, and it's important this is
        // done here and not deeper into Blade tree.

        const getValue = (defaultValue: any) => {
          if (

            (question.questionType === 'Date' ||
              question.questionType === 'MonthYear') &&
            typeof _value === 'string'
          ) {
            return formatters.dateToApplicationReadable(_value);
          }

          return defaultValue;
        };

        const commonInputProps = {
          name: question.referenceID,
          id: question.referenceID,
          value: getValue(_value),
          onRiskUpdate: (value: any, riskPath: string) => {
            updateRisk(riskPath, value, false);
          },
          onChange: (value: any) => {
            if (
              question.questionType === 'Date' ||
              question.questionType === 'MonthYear' ||
              question.questionType === 'DatePicker'
            ) {
              updateRisk(
                question.riskPath,
                formatters.dateToPlatformReadable(value),
                false
              );
            } else if (question.questionType === 'DateRange') {
              var updatedDateRangeValue = {
                from: formatters.dateToPlatformReadable(value.from),
                to: formatters.dateToPlatformReadable(value.to)
              };

              updateRisk(question.riskPath, updatedDateRangeValue, false);
            } else if (question.questionType === 'Grouped') {
              var updatedValue = {
                line1: value.line1,
                line2: value.line2,
                line3: value.line3,
                locality: value.locality,
                postalCode: value.postalCode,
                country: value.country,
                province:
                  typeof value.province === 'object' &&
                  value.province?.text === null &&
                  value.province?.referenceID === null
                    ? null
                    : value.province,
                provinceReferenceID: value.provinceReferenceID,
                uniqueReference: value.uniqueReference
              };

              updateRisk(question.riskPath, updatedValue, isCollection);
            } else if (question.questionType === 'Integer') {
              var num = parseInt(value);
              updateRisk(
                question.riskPath,
                !Number.isNaN(num) ? num : null,
                isCollection
              );
            } else if (question.questionType === 'InputDate') {
              var isValid = runValidation(
                question.validationRules,
                question.validation,
                false
              )(value);

              updateRisk(
                question.riskPath,
                value,
                isCollection,
                isValid === true
              );
            } 
             else {
              updateRisk(question.riskPath, value, isCollection);
            }
          },
          validate: (value: any) => {
            // TODO
            // For some reason the validation functions were designed
            // to return 'undefined' if the value was valid and a string with the
            // validation error if invalid.
            // This is a bit weird and counter-intuitive. For the time being it's
            // fine to handle this every time the validation function
            // is called but eventually we need to clean up the validation
            // functions to return 'true' when the value is valid.

            var _validationResult = runValidation(
              question.validationRules,
              question.validation,
              false
            )(value);

            if (Array.isArray(_validationResult)) {
              [_validationResult] = _validationResult;
            }

            /* This is a special case here, we want the validator to run for inputdate regardless
               This is the only way we can get this to disable the button
               We also need to pass the formatter down to the validator
            */
            if (
              question.questionType === 'InputDate' ||
              question.questionType === 'InputDateMonthYear'
            ) {
              const format =
                question.questionType === 'InputDateMonthYear'
                  ? 'MM/YYYY'
                  : settings.dateFormat || 'DD/MM/YYYY';

              var inbuiltValidation = builtInInputDateValidator(
                value,
                format,
                formatters.dateToApplicationReadable
              );

              /* TODO: The below needs some tidying up at some point as its hard to read*/
              if (
                inbuiltValidation !== undefined &&
                (_validationResult === undefined || _validationResult === true)
              ) {
                _validationResult = inbuiltValidation;
              }
            }

            if (question.questionType === 'DateRange') {
              const format = settings.dateFormat || 'DD/MM/YYYY';
              _validationResult = isValidDateRange(
                value.from,
                value.to,
                format,
                question.validationRules,
                formatters.dateToApplicationReadable
              );
            }

            if (
              question.questionType === 'PhoneNumber' &&
              question.validationRules.find(
                item => item === 'mustBeValidPhoneNumberAU'
              )
            ) {
              _validationResult =
                mustBeValidPhoneNumberAU(
                  value,
                  settings?.countryCode?.trim()
                ) || true;
            } else if (
              question.questionType === 'PhoneNumber' &&
              question.validationRules.find(
                item => item === 'mustBeValidPhoneNumber'
              )
            ) {
              _validationResult =
                mustBeValidPhoneNumber(value, settings?.countryCode?.trim()) ||
                true;
            }

            if (question.questionType === 'MotorWeb') {
              _validationResult = motorWebMustHaveVehicleDetails(value);
            }

            if (
              (question.questionType === 'ItemArrayFormGeneric' ||
                question.questionType === 'ItemArrayForm') &&
              Array.isArray(value) &&
              value.length > 0
            ) {
              let modalValidationResult: string[] = [];

              // Scenario: the a subset of the modal question set is populated on a previous page
              // foreach item in the array, validate each modal question
              value.forEach(item => {
                question.modal?.questions.forEach(modalQ => {
                  var modalQValue = item;
                  if (modalQ.riskPath.includes('.')) {
                    var riskPath = modalQ.riskPath.substring(
                      modalQ.riskPath.indexOf('.') + 1
                    );
                    modalQValue = getFromForm(item, riskPath);
                  }

                  var modalQValidationResult = runValidation(
                    modalQ.validationRules,
                    modalQ.validation,
                    false
                  )(modalQValue);

                  if (
                    !(
                      modalQValidationResult === undefined ||
                      modalQValidationResult === true
                    )
                  ) {
                    if (Array.isArray(modalQValidationResult)) {
                      modalValidationResult = modalValidationResult.concat(
                        modalQValidationResult
                      );
                    } else {
                      modalValidationResult.push(modalQValidationResult);
                    }
                  }
                });
              });

              if (modalValidationResult.length > 0) {
                // Retrieve this message from config
                modalValidationResult.unshift(
                  question.modal?.validationFailureText ||
                    'Please fill in the missing values.'
                );
                [_validationResult] = modalValidationResult;
              }
            }

            return _validationResult === undefined || _validationResult === true
              ? true
              : _validationResult;
          },
          onValidate: (validationResult: ValidationResult) => {
            dispatchValidation({
              type: 'validate',
              value: { [question.referenceID]: validationResult }
            });
          },
          displayValidationErrorsOnLoad:
            question.displayValidationErrorsOnLoad && !!quoteId,
          error: validation[question.referenceID],
          key: question.referenceID,
          parentQuestion
        };

        const commonInputWithFieldsetsProps = {
          collectionTitle: question.modal?.title,
          addToCollectionLabel: question.modal?.buttonText,
          addAnotherToCollectionLabel: question.modal?.multipleButtonText,
          itemLabel: question.text.answeredText,
          disclaimer: question.text.additionalQuestionDescription,
          customProperties: question.customProperties,
          itemKey: question.modal?.questions[0].riskPath.split('.')[0],
          getQuestions: (
            formState: any,
            updateFormState: any,
            validation: any,
            dispatchValidation: any
          ) => {
            return mapQuestionMetaToQuestionFieldset(
              question.modal?.questions,
              formState,
              originalRisk,
              updateFormState,
              validation,
              dispatchValidation,
              settings,
              formatters,
              orgConfiguration
            );
          }
        };

        const commonClaimsProps = {
          claimsQuestionNames: productConfiguration?.product.claims
        };

        //If we do not want to render the question than leave it
        if (question.customProperties?.visible === false) {
          return null;
        }

        // Roadside logic for cars older than 15 years
        const roadsideRef = 'stella_q_roadside_assistance';
        const isRoadsideQuestion = question.referenceID === roadsideRef;

        const roadsideAddOnIsHidden = () => {
          const roadsideQ = questions.find(
            x => x.referenceID === roadsideRef
          );

          return roadsideIsHiddenOracle(
            roadsideQ,
            risk,
            quoteType,
            updateRisk
          );
        };

        // TODO
        // Explain why this is here, and why
        // the verbosity.

        switch (question.questionType) {
          case 'Grouped':
            var countryField = question.fields.filter(f => {
              return f.riskPath === 'country';
            })[0];

            return (
              <BladeFormFieldsetAddressInternational
                {...commonFieldsetProps}
                {...commonInputProps}
                dispatchReset={() =>
                  dispatchValidation({
                    type: 'reset',
                    value: {
                      [question.referenceID]: {
                        ...validation[question.referenceID]
                      }
                    }
                  })
                }
                isGroup={true}
                selectedCountry={
                  _value?.country ? _value.country : countryField.defaultAnswer
                }
                showCountry={
                  countryField.customProperties?.visible !== null &&
                  countryField.customProperties?.visible !== undefined
                    ? countryField.customProperties.visible
                    : true
                }
                renderOracle={
                  settings.oracles.address?.name &&
                  settings.oracles.address?.url
                    ? switchAddressOracle(
                        { ...commonFieldsetProps, ...commonInputProps },
                        settings.oracles.address.name,
                        settings.oracles.address.url,
                        settings.oracles.address.token,
                        settings.oracles.misc?.text
                      )
                    : null
                }
              >
                {mapQuestionMetaToQuestionFieldset(
                  question.fields,
                  _value,
                  originalRisk,
                  (fieldAddress, value) => {
                    // TODO: This is a temporary fix.
                    // At some point the risk path is being overwritten
                    // and therefore the value is not being set.
                    // Needs further investication.
                    updateRisk(
                      question.riskPath + `.${fieldAddress}`,
                      value,
                      isCollection
                    );
                  },
                  validation[question.referenceID] || {},
                  (result: any) => {
                    dispatchValidation({
                      type: 'validate',
                      value: {
                        [question.referenceID]: {
                          ...validation[question.referenceID],
                          ...result.value
                        }
                      }
                    });
                  },
                  settings,
                  formatters,
                  orgConfiguration
                )}
              </BladeFormFieldsetAddressInternational>
            );

          case 'Name':
            return (
              <BladeFormFieldsetName
                {...commonFieldsetProps}
                {...commonInputProps}
              />
            );

          case 'Address':
            return (
              <BladeFormFieldsetAddress
                {...commonFieldsetProps}
                {...commonInputProps}
                options={
                  question.possibleAnswers &&
                  question.possibleAnswers.length > 0
                    ? question.possibleAnswers
                    : countries
                }
                matchOn={'referenceID'}
                renderOracle={
                  settings.oracles.address?.name &&
                  settings.oracles.address?.url
                    ? switchAddressOracle(
                        { ...commonFieldsetProps, ...commonInputProps },
                        settings.oracles.address.name,
                        settings.oracles.address.url,
                        settings.oracles.address.token
                      )
                    : null
                }
              />
            );

          case 'YesNo':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSegmented
                  {...commonInputProps}
                  isYesNo={true}
                  isInline={true}
                  options={[
                    {
                      value: true,
                      text: 'Yes'
                    },
                    {
                      value: false,
                      text: 'No'
                    }
                  ]}
                  matchOn={'value'}
                />
              </BladeFormFieldset>
            );

          case 'TextBlock':
            return <BladeTextBlock {...commonFieldsetProps} />;

          case 'DatePicker':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDatePicker
                  {...commonInputProps}
                  timezone={settings.timezone}
                  validationRules={question.validationRules}
                  toPlatformReadable={formatters.dateToPlatformReadable}
                  toApplicationReadable={formatters.dateToApplicationReadable}
                />
              </BladeFormFieldset>
            );

          case 'DateRange':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDateRange
                  {...commonInputProps}
                  timezone={settings.timezone}
                  validationRules={question.validationRules}
                  toPlatformReadable={formatters.dateToPlatformReadable}
                  toApplicationReadable={formatters.dateToApplicationReadable}
                  dateFormat={question.customProperties?.dateFormat}
                  permissions={question.customProperties?.changeControl}
                />
              </BladeFormFieldset>
            );

          case 'Date':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDate
                  {...commonInputProps}
                  now={formatters.dateToPlatformReadable(
                    formatters.dateToApplicationReadable()
                  )}
                />
              </BladeFormFieldset>
            );

          case 'MonthYear':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDate
                  {...commonInputProps}
                  now={formatters.dateToPlatformReadable(
                    formatters.dateToApplicationReadable()
                  )}
                />
              </BladeFormFieldset>
            );

          case 'Email':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeEmail
                  fullDomains={orgConfiguration.emailSuggestion?.customDomains}
                  {...commonInputProps}
                />
              </BladeFormFieldset>
            );

          case 'Integer':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputNumber {...commonInputProps} />
              </BladeFormFieldset>
            );

          case 'String':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputText {...commonInputProps} />
              </BladeFormFieldset>
            );

          case 'Money':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputMoney
                  {...commonInputProps}
                  currencyCode={settings.currencyCode}
                  currencySymbol={settings.currencySymbol}
                />
              </BladeFormFieldset>
            );

          case 'PhoneNumber':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputText type="tel" {...commonInputProps} />
              </BladeFormFieldset>
            );

          case 'Radio':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputRadio {...commonInputProps} />
              </BladeFormFieldset>
            );

          case 'Segmented':
            if (!question.possibleAnswers) {
              throw new QuestionMissingConfiguration(
                'Tried to render `Segmented` question without any `possibleAnswers`'
              );
            }

            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSegmented
                  {...commonInputProps}
                  options={question.possibleAnswers}
                  matchOn={'referenceID'}
                  displayType={question.customProperties?.displayType}
                />
              </BladeFormFieldset>
            );

          case 'Voucher':
            return questionConfig && questionConfig.hideVoucher ? null : (
              <BladeInputVoucher
                risk={risk}
                setRiskHash={setRiskStateHash}
                tobesState={riskState}
                voucherConfiguration={productConfiguration?.quote.voucher}
                fieldsetProps={commonFieldsetProps}
                {...commonInputProps}
              />
            );
          case 'Picklist':
            if (!question.possibleAnswers) {
              throw new QuestionMissingConfiguration(
                'Tried to render `Picklist` question without any `possibleAnswers`'
              );
            }

            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSelect
                  {...commonInputProps}
                  options={question.possibleAnswers}
                  matchOn={'referenceID'}
                />
              </BladeFormFieldset>
            );

          case 'ItemArray':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputCollection
                  {...commonInputProps}
                  {...commonInputWithFieldsetsProps}
                />
              </BladeFormFieldset>
            );

          case 'ItemArrayForm':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeClaims
                  {...commonInputProps}
                  {...commonClaimsProps}
                  {...commonInputWithFieldsetsProps}
                />
              </BladeFormFieldset>
            );
          case 'ItemArrayFormGeneric':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeItemArrayFormGeneric
                  {...commonInputProps}
                  {...commonInputWithFieldsetsProps}
                  maxNumberOfItems={question.customProperties?.maxNumberOfItems}
                  permissions={question.customProperties?.changeControl}
                  defaultItemValue={question.defaultAnswer}
                  originalValue={getFromForm(originalRisk, question.riskPath)}
                />
              </BladeFormFieldset>
            );

          case 'MoneyStepper':
            if (!question.possibleAnswers) {
              throw new QuestionMissingConfiguration(
                'Tried to render `MoneyStepper` question without any `possibleAnswers`'
              );
            }

            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSelect
                  {...commonInputProps}
                  isStepper={true}
                  options={question.possibleAnswers}
                  matchOn={'referenceID'}
                />
              </BladeFormFieldset>
            );

          case 'BoxBoolean':

            const roadsideQuestionTypeIsBoxBoolean = () => {
              const roadsideQ = questions.find(
                x => x.referenceID === roadsideRef
              );

              return roadsideQ?.questionType === "BoxBoolean";
            };

            const legacyShouldHideRoadside = roadsideAddOnIsHidden();

            const skipRender = isRoadsideQuestion && legacyShouldHideRoadside;

            const isLegacyRAQuestion = roadsideQuestionTypeIsBoxBoolean()

            // Optional Extras title
            const displayOptionalExtrasTitle = (isRoadsideQuestion ||
              (question.referenceID === "stella_q_excess_free_windscreen" && (legacyShouldHideRoadside || !isLegacyRAQuestion)))

            return (
              !skipRender && (
                <BladeFormFieldset
                  displayOptionalTitle={displayOptionalExtrasTitle}
                  topSpace={
                    productConfiguration?.productReference ===
                      'stella_pr_motor' ||
                    productConfiguration?.productReference ===
                      'stella_pr_motor_asu'
                  }
                  isPanel={PanelLayout.ThreeColumns}
                  {...commonFieldsetProps}
                >
                  <BladeInputSwitch
                    {...commonInputProps}
                    label={question.text.footerText}
                    variant={'no-frame'}
                  />
                </BladeFormFieldset>
              )
            );

          case 'BoxModal':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputCollection
                  {...commonInputProps}
                  {...commonInputWithFieldsetsProps}
                />
              </BladeFormFieldset>
            );

          case 'BoxMoneyStepper':
            if (!question.possibleAnswers) {
              throw new QuestionMissingConfiguration(
                'Tried to render `BoxMoneyStepper` question without any `possibleAnswers`'
              );
            }

            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSelect
                  {...commonInputProps}
                  isStepper={true}
                  options={question.possibleAnswers}
                  matchOn={'referenceID'}
                />
              </BladeFormFieldset>
            );

          case 'SingleEntityModal':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputCollection
                  {...commonInputProps}
                  {...commonInputWithFieldsetsProps}
                  maxNumberOfItems={1}
                />
              </BladeFormFieldset>
            );

          case 'SingleEntityBoxModal':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputCollection
                  {...commonInputProps}
                  {...commonInputWithFieldsetsProps}
                  maxNumberOfItems={1}
                />
              </BladeFormFieldset>
            );

          case 'PagedBoolean':
            const keyReplacementsMap = objectMap(
              question.text.data,
              objectItem => ({ [objectItem]: `@${objectItem}` })
            );

            const updatedMarkdown = stringReplacer(
              question.text.data?.['main-page'],
              keyReplacementsMap,
              true
            );

            return (
              <BladeFormFieldset
                {...commonFieldsetProps}
                description={updatedMarkdown}
                descriptionPages={question.text.data}
              >
                <BladeInputSegmented
                  {...commonInputProps}
                  isYesNo={true}
                  isInline={true}
                  options={[
                    {
                      value: true,
                      text: 'Yes'
                    },
                    {
                      value: false,
                      text: 'No'
                    }
                  ]}
                  matchOn={'value'}
                />
              </BladeFormFieldset>
            );

          case 'MotorWeb':
            return (
              <BladeFormFieldsetMotor
                {...commonFieldsetProps}
                {...commonInputProps}
                identifierKey={'nvic'}
                identifierKeyFallback={'make'}
                registationMaxLength={9}
                renderRegistrationOracle={
                  settings.oracles.motor?.registration.name &&
                  settings.oracles.motor?.registration.url
                    ? switchMotorOracle(
                        { ...commonFieldsetProps, ...commonInputProps },
                        settings.oracles.motor.registration.name,
                        settings.oracles.motor.registration.url,
                        settings.oracles.motor.registration.token,
                        settings.oracles.motor.carDetails.url,
                        settings.oracles.motor.carDetails.token
                      )
                    : null
                }
                renderCarDetailsOracle={
                  settings.oracles.motor?.carDetails.name &&
                  settings.oracles.motor?.carDetails.url
                    ? switchMotorOracle(
                        { ...commonFieldsetProps, ...commonInputProps },
                        settings.oracles.motor.carDetails.name,
                        settings.oracles.motor.carDetails.url,
                        settings.oracles.motor.carDetails.token
                      )
                    : null
                }
              />
            );

          case 'BoxConfirmationBoolean':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputIncluded
                  {...commonInputProps}
                  variant={'no-frame'}
                />
              </BladeFormFieldset>
            );

          case 'MoneyBoundedSlider':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputSlider
                  {...commonInputProps}
                  optionsFormatted={question.possibleAnswers?.map(
                    possibleAnswer => ({
                      value: possibleAnswer.text?.replace('.00', '') // Comes back from backend with pence, we need to manage this a lot better rather than lopping it off
                    })
                  )}
                  options={question.possibleAnswers}
                  showMiddleValue={false}
                  showResetButton={false}
                  defaultValue={
                    commonInputProps.value
                      ? commonInputProps.value.text.replace('.00', '')
                      : question?.defaultAnswer?.text?.replace('.00', '')
                  }
                  footerText={question.text.footerText}
                />
              </BladeFormFieldset>
            );

          case 'DynamicMoneyBoundedSlider':
            return (
              <BladeFormFieldset
                {...commonFieldsetProps}
                key={question.riskPath}
              >
                <BladeInputSlider
                  {...commonInputProps}
                  optionsFormatted={question.content?.allowedValues.map(
                    (allowedValue: any) => ({
                      value: currencyToHumanReadable(allowedValue.value)
                    })
                  )}
                  options={question.content?.allowedValues}
                  showMiddleValue={false}
                  showResetButton={false}
                  defaultValue={
                    commonInputProps.value
                      ? currencyToHumanReadable(commonInputProps.value.value)
                      : currencyToHumanReadable(question.content?.marketValue)
                  }
                  footerText={question.text.footerText}
                />
              </BladeFormFieldset>
            );
          case 'DynamicMoneyBoundedSliderRange':
            return (
              <BladeFormFieldset
                {...commonFieldsetProps}
                key={question.riskPath}
              >
                <BladeInputRangeSlider
                  {...commonInputProps}
                  interval={question.content.interval}
                  footerText={question.text.footerText}
                  min={roundUp(
                    question.content.minValue.value,
                    question.content.interval
                  )}
                  max={roundDown(
                    question.content.maxValue.value,
                    question.content.interval
                  )}
                  defaultValue={question.content.marketValue.value}
                  currencyCode={question.content.maxValue.currencyCode}
                  previousValue={question.riskValueFromSource}
                />
              </BladeFormFieldset>
            );
          case 'InputDate':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDateManualEntry
                  {...commonInputProps}
                  now={formatters.dateToPlatformReadable(
                    formatters.dateToApplicationReadable()
                  )}
                  dateFormat={
                    settings.dateFormat ? settings.dateFormat : 'DD/MM/YYYY'
                  }
                />
              </BladeFormFieldset>
            );
          case 'InputDateMonthYear':
            return (
              <BladeFormFieldset {...commonFieldsetProps}>
                <BladeInputDateManualEntry
                  {...commonInputProps}
                  now={formatters.dateToPlatformReadable(
                    formatters.dateToApplicationReadable()
                  )}
                  dateFormat={'MM/YYYY'}
                />
              </BladeFormFieldset>
            );

          case 'SectionHeader':
            return (
              <BladeFormFieldset isSectionHeader>
                <h1>{question.text.questionText}</h1>
                <p>{question.text.helpText}</p>
              </BladeFormFieldset>
            );

          case 'TravelPackage':
          case 'TravelPackageDateRange':
            return (
              <BladeFormFieldset isPanel={PanelLayout.TwoColumns}>
                <BladeFormFieldsetTravelPackage
                  {...commonFieldsetProps}
                  {...commonInputProps}
                  questionData={question}
                  additionalComponentPrices={
                    pricingPlan?.addOns || mtaPricing?.addOns
                  }
                  selectedInOriginalRisk={
                    originalRisk
                      ? getFromForm(
                          originalRisk,
                          `${question.riskPath}.selected`
                        ) === true
                      : null
                  }
                  isLoadingPrice={isLoadingPrice ?? false}
                >
                  {question.fields &&
                    question.fields.length > 0 &&
                    mapQuestionMetaToQuestionFieldset(
                      question.fields,
                      _value,
                      originalRisk,
                      (fieldAddress, value) => {
                        updateRisk(
                          question.riskPath + `.${fieldAddress}`,
                          value,
                          isCollection
                        );
                      },
                      validation[question.referenceID] || {},
                      (result: any) => {
                        dispatchValidation({
                          type: 'validate',
                          value: {
                            [question.referenceID]: {
                              ...validation[question.referenceID],
                              ...result.value
                            }
                          }
                        });
                      },
                      settings,
                      formatters,
                      orgConfiguration,
                      productConfiguration,
                      riskState,
                      setRiskStateHash,
                      questionConfig,
                      pricingPlan,
                      mtaPricing,
                      quoteId,
                      isLoadingPrice,
                      question.referenceID
                    )}
                </BladeFormFieldsetTravelPackage>
              </BladeFormFieldset>
            );
          case 'RoadsideAssistance': 

          const shouldHideRoadside = roadsideAddOnIsHidden();

          const skipRoadsideRender = isRoadsideQuestion && shouldHideRoadside;

          return (
            !skipRoadsideRender && (
              <BladeFormFieldset isPanel={PanelLayout.TwoColumns}>
                <BladeFormFieldsetRoadsideAssistance
                  {...commonFieldsetProps}
                  {...commonInputProps}
                  questionData={question}
                  additionalComponentPrices={
                    pricingPlan?.addOns || mtaPricing?.addOns
                  }
                  selectedInOriginalRisk={
                    originalRisk
                      ? getFromForm(originalRisk, question.riskPath) === true
                      : null
                  }
                  isLoadingPrice={isLoadingPrice ?? false}
                >
                  {question.fields &&
                    question.fields.length > 0 &&
                    mapQuestionMetaToQuestionFieldset(
                      question.fields,
                      _value,
                      originalRisk,
                      (fieldAddress, value) => {
                        updateRisk(
                          question.riskPath + `.${fieldAddress}`,
                          value,
                          isCollection
                        );
                      },
                      validation[question.referenceID] || {},
                      (result: any) => {
                        dispatchValidation({
                          type: 'validate',
                          value: {
                            [question.referenceID]: {
                              ...validation[question.referenceID],
                              ...result.value
                            }
                          }
                        });
                      },
                      settings,
                      formatters,
                      orgConfiguration,
                      productConfiguration,
                      riskState,
                      setRiskStateHash,
                      questionConfig,
                      pricingPlan,
                      mtaPricing,
                      quoteId,
                      isLoadingPrice,
                      question.referenceID
                    )}
                </BladeFormFieldsetRoadsideAssistance>
              </BladeFormFieldset>
          ));

            default:
            throw new ApplicationError('Unhandled question type');
        }
      })

      // We'll also make sure to filter out any
      // of the null values (due to dependencies not satisified).

      .filter(question => question !== null)
  );
}

interface Settings {
  currencyCode: string;
  currencySymbol: string;
  countryCode?: string;
  timezone?: string;
  dateFormat?: string;
  oracles: OraclesConfiguration;
  questionIcons?: { [referenceID: string]: string };
  questionImages?: { [referenceID: string]: string };
}

interface Formatters {
  dateToHumanReadable: ToHumanReadable;
  dateToPlatformReadable: ToPlatformReadable;
  dateToApplicationReadable: ToApplicationReadable;
}
