import * as React from 'react';

import { BladeButton } from '../../button';
import { BladeInput_Frame } from '../_frame';
import {
  ChangePermissions,
  IntellegentQuoteQuestionCustomProperties
} from '@aventus/platform';
import { ItemElement } from './components/item';
import { ItemForm } from './components/itemForm';
import css from './index.css';
import cx from 'classnames';
import formCss from './components/itemForm/index.css';
import { isEmpty } from 'lodash';
import { IBladeUseInput, useInput } from '../use-input';
import { useItemArrayFormCollection } from './use-item-array-form-collection';
import { useMediaQuery } from '@aventus/pockethooks';
import { BladeMarkdown } from '@aventus/blade';

export interface IBladeItemArrayItem {
  referenceID: string;
  answerId: string;
  text: string;
}

interface IBladeUseItemArrayInput extends IBladeUseInput {
  value: IBladeItemArrayItem[];
}

export const BladeItemArrayFormGeneric: React.FunctionComponent<
  IBladeItemArrayFormGeneric
> = props => {
  const [isFormModified, setIsFormModified] = React.useState<
    number | undefined
  >(undefined);
  const inputProps: IBladeUseItemArrayInput = useInput(props);

  const defaultFormState = {
    [props.itemKey]: props.defaultItemValue
  };

  const {
    isFormOpen,
    setIsFormOpen,
    formState,
    setFormState,
    updateFormState,
    addToCollection,
    updateInCollection,
    deleteFromCollection,
    resolvePermission
  } = useItemArrayFormCollection(
    inputProps.value,
    props.originalValue,
    defaultFormState
  );

  const { matchesQuery } = useMediaQuery('(max-width: 769px)');

  const canAddMoreItems =
    !props.maxNumberOfItems ||
    inputProps.value?.length < props.maxNumberOfItems;
  const isAllowedToAdd = props.permissions?.canAdd !== false;
  const shouldFormBeOpen = !inputProps.value || isFormOpen;
  const showAddButton = !shouldFormBeOpen && canAddMoreItems && isAllowedToAdd;

  const showDisclaimer =
    props.customProperties?.additionalListItemDetail?.items?.find(
      x =>
        props.value?.find(val => val.referenceID === x.referenceID) &&
        x.showAdditionalText
    ) != null ?? false;

  const formAdd = (isFormValid: boolean) => (
    <div className={formCss.collection_form}>
      <div
        className={cx(formCss.collection_form_buttons, {
          [formCss.collection_form_buttons_wide]: matchesQuery
        })}
      >
        <BladeButton
          className={cx(formCss.collection_form_button, {
            [formCss.collection_form_button_wide]: matchesQuery
          })}
          isDisabled={!isFormValid}
          onClick={() => {
            inputProps.onChange(addToCollection(formState[props.itemKey]));
            setFormState(defaultFormState);
            setIsFormOpen(false);
          }}
        >
          {props.addToCollectionLabel || 'Add'}
        </BladeButton>

        {inputProps.value?.length > 0 && (
          <BladeButton
            className={cx(formCss.collection_form_button, {
              [formCss.collection_form_button_wide]: matchesQuery
            })}
            variant="secondary"
            onClick={() => setIsFormOpen(false)}
          >
            Cancel
          </BladeButton>
        )}
      </div>
    </div>
  );

  const formModify = (
    isFormValid: boolean,
    setIsEditMode: (value: boolean) => void,
    itemIndex: number
  ): React.ReactNode => {
    return (
      <div className={formCss.collection_form}>
        <div
          className={cx(formCss.collection_form_buttons, {
            [formCss.collection_form_buttons_wide]: matchesQuery
          })}
        >
          <BladeButton
            className={cx(formCss.collection_form_button, {
              [formCss.collection_form_button_wide]: matchesQuery
            })}
            isDisabled={!isFormValid}
            onClick={() => {
              inputProps.onChange(
                updateInCollection(formState[props.itemKey], itemIndex)
              );
              setFormState(defaultFormState);
              setIsEditMode(false);
            }}
          >
            Update details
          </BladeButton>
          <BladeButton
            className={cx(formCss.collection_form_button, {
              [formCss.collection_form_button_wide]: matchesQuery
            })}
            variant="secondary"
            onClick={() => {
              setIsEditMode(false);
              setIsFormOpen(false);
            }}
          >
            Cancel
          </BladeButton>
        </div>
      </div>
    );
  };

  return (
    <BladeInput_Frame
      hasBeenInteractedWith={inputProps.hasBeenInteractedWith}
      error={inputProps.error}
    >
      {inputProps.hasBeenInteractedWith === false &&
        inputProps.value?.length > 0 &&
        typeof props.error === 'string' && (
          <small className={css._frame_error}>
            {props.error || 'Please fill in the missing values.'}
          </small>
        )}

      <div className={css.collection}>
        {inputProps.value && (
          <>
            <div className={css.collection_content}>
              <>
                {inputProps.value.map((value, index) => (
                  <ItemElement
                    key={`${props.itemKey}_${index}`}
                    modifyItem={formModify}
                    canDelete={resolvePermission(
                      props.permissions?.canRemove,
                      value.answerId
                    )}
                    canModify={resolvePermission(
                      props.permissions?.canModify,
                      value.answerId
                    )}
                    isFormModified={isFormModified}
                    setIsFormModified={setIsFormModified}
                    itemKey={props.itemKey}
                    setIsFormOpen={setIsFormOpen}
                    itemIndex={index}
                    itemLabel={props.itemLabel}
                    setFormState={setFormState}
                    deleteItem={() => {
                      inputProps.onChange(deleteFromCollection(index));
                      inputProps.value.length === 1 && setIsFormOpen(true);
                    }}
                    getQuestions={(validation: any, dispatchValidation: any) =>
                      props.getQuestions(
                        formState,
                        updateFormState,
                        validation,
                        dispatchValidation
                      )
                    }
                    values={value}
                  />
                ))}
              </>
            </div>
          </>
        )}
      </div>
      {showDisclaimer && (
        <BladeMarkdown
          className={css.additionalText}
          markdown={props.disclaimer}
        />
      )}
      {showAddButton && (
        <BladeButton
          className={cx(formCss.collection_form_button, {
            [formCss.collection_form_button_wide]: matchesQuery
          })}
          onClick={() => {
            setFormState(defaultFormState);
            setIsFormOpen(true);
            setIsFormModified(undefined);
          }}
        >
          {!inputProps.value || isEmpty(inputProps.value)
            ? props.addToCollectionLabel || 'Add'
            : props.addAnotherToCollectionLabel || 'Add another'}
        </BladeButton>
      )}

      {shouldFormBeOpen && (
        <ItemForm
          formActions={formAdd}
          getQuestions={(validation: any, dispatchValidation: any) =>
            props.getQuestions(
              formState,
              updateFormState,
              validation,
              dispatchValidation
            )
          }
        />
      )}
    </BladeInput_Frame>
  );
};

export interface IBladeItemArrayFormGeneric extends IBladeInput {
  originalValue: IBladeItemArrayItem[];
  value: IBladeItemArrayItem[];
  addToCollectionLabel: string | null;
  addAnotherToCollectionLabel: string | null;
  disclaimer: Nullable<string>;
  customProperties: Nullable<IntellegentQuoteQuestionCustomProperties>;
  itemLabel: string | null;
  itemKey: string;
  maxNumberOfItems?: number | null;
  permissions?: ChangePermissions;
  defaultItemValue: Object | null;
  getQuestions: (
    formState: any,
    updateFormState: any,
    validation: any,
    dispatchValidation: any
  ) => any;
}
