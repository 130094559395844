import * as React from 'react';

import { BladeButton, BladeIcon, BladeLoader } from '@aventus/blade';

import styles from './css/styles.css';
import { useEffect } from 'react';

interface PackageSelectorButtonsProps {
  packageSelected: boolean;
  setPackageSelected: (packageSelected: boolean ) => void;
  packagePrice?: string | null;
  canBeRemoved: boolean;
  showAddButton: boolean;
  packageWasInteractedWith: boolean;
  packageIsBeingAdded: boolean;
  packageIsBeingRemoved: boolean;
  childComponentUpdated?: boolean;
  addText?: string;
  addedText?: Nullable<string>;
  toggleButtonText?: Nullable<string>;
  toggleButtonOn?: React.Dispatch<React.SetStateAction<undefined>>;
  isSelectedTier?: boolean;
  isToggleMode: boolean;
}

const PackageButtons = (props: PackageSelectorButtonsProps) => {
  const [packagedIsBeingToggledOn, setPackageIsBeingToggledOn] =
    React.useState(false);
  const [packagedIsBeingToggledOff, setPackageIsBeingToggledOff] =
    React.useState(false);
  
  const loaderColour = '#292E39';

  const setPackageSelected = (isSelected: boolean) => {
    if (props.isToggleMode) {

      isSelected
        ? setPackageIsBeingToggledOn(true)
        : setPackageIsBeingToggledOff(true);

      props.setPackageSelected(isSelected);
      
    } else {
      props.setPackageSelected(true);
    }
  };

  useEffect(() => {
    if (packagedIsBeingToggledOn && !props.packageIsBeingAdded) {
      setPackageIsBeingToggledOn(false);
      props.toggleButtonOn && props.toggleButtonOn(undefined);
    }

    if (packagedIsBeingToggledOff && !props.packageIsBeingRemoved) {
      setPackageIsBeingToggledOff(false);
      props.toggleButtonOn && props.toggleButtonOn(undefined);
    }
  }, [props.packageIsBeingAdded, props.packageIsBeingRemoved]);

  const packageIsSelectable = () => {
    if (props.isToggleMode) {
      if (packagedIsBeingToggledOn){
        return true;
      }
      else if (packagedIsBeingToggledOff){
        return false;
      }
      else if (!props.packageSelected){
        return true;
      }
      else {
        return !props.isSelectedTier; // if this tier isn't selected
      }
    }

    if (!props.isToggleMode && (props.showAddButton || packagedIsBeingToggledOn)) {
      return true;
    }

    return false;
  };

  // Loading states
  const addBtnIsLoading =
    (props.isToggleMode && packagedIsBeingToggledOn) ||
    (!props.isToggleMode &&
      ((packagedIsBeingToggledOn && props.packageIsBeingAdded) ||
        props.packageWasInteractedWith));
  const removeButtonIsLoading =
    (props.isToggleMode && packagedIsBeingToggledOff) ||
    (!props.isToggleMode &&
      (props.packageIsBeingRemoved || props.packageWasInteractedWith));

  return (
    <div className={styles.packageButtons}>
      {packageIsSelectable() && (
        <BladeButton
          className={props.isToggleMode ? styles.addToQuoteButtonToggle : styles.addToQuoteButton}
          onClick={() => setPackageSelected(true)}
        >
          {addBtnIsLoading && <BladeLoader color="white" />}
          {!addBtnIsLoading && (
            <>
              <span>
                {props.packageSelected
                  ? props.toggleButtonText || props.addText
                  : props.addText || 'Add to quote'}
              </span>
              {props.packagePrice && !props.childComponentUpdated && (
                <div>
                  <strong>{props.packagePrice}</strong>
                </div>
              )}
              {props.packagePrice && props.childComponentUpdated && (
                <div className={styles.priceLoaderContainer}>
                  <BladeLoader color="white" className={styles.priceLoader} />
                </div>
              )}
            </>
          )}
        </BladeButton>
      )}

      {(!packageIsSelectable() || packagedIsBeingToggledOff) && (
        <>
          <p className={props.isToggleMode ? styles.packageSelectedToggle : styles.packageSelected}>
            {(props.packageIsBeingRemoved ||
              props.packageWasInteractedWith) && (
              <BladeLoader color={loaderColour} />
            )}
            {removeButtonIsLoading && <BladeLoader color={loaderColour} />}
            {!removeButtonIsLoading && (
              <>
                <span>
                  <BladeIcon color="green" name={'check-circle'} />
                  {props.canBeRemoved
                    ? props.addedText || 'Added to quote'
                    : 'Included'}
                </span>

                {props.packagePrice && !props.childComponentUpdated && (
                  <div>
                    <strong>{props.packagePrice}</strong>
                  </div>
                )}
                {props.packagePrice && props.childComponentUpdated && (
                  <div className={styles.priceLoaderContainer}>
                    <BladeLoader
                      color={loaderColour}
                      className={styles.priceLoader}
                    />
                  </div>
                )}
              </>
            )}
          </p>

          {props.canBeRemoved && (

            <BladeButton
              className={`${styles.removeFromQuoteButton} ${styles.mobileOnly}`}
              variant="secondary"
              isInline
              onClick={() => setPackageSelected(false)}
              isDisabled={props.packageWasInteractedWith}
            >
              <BladeIcon name={'faTrashAlt'} />
            </BladeButton>
          )}
        </>
      )}
    </div>
  );
};

export default PackageButtons;
