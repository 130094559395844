import {
  AdditionalComponentPrices,
  IntelligentQuoteQuestion
} from '@aventus/platform';

import React from 'react';
import { currencyToHumanReadable } from '@aventus/pocketknife';
import { isString } from '@aventus/pocketknife/typeguards';

interface PackageInfo {
  price: string | null;
  limit: string | null;
}

export interface ITieredPackage {
  selected: boolean;
  level: Nullable<string>
}

export const usePackageSelector = (
  question: IntelligentQuoteQuestion,
  packageSelectedValue: boolean,
  selectedInOriginalRisk: boolean | null,
  isLoading: boolean,
  onChange: (isSelected: boolean  | ITieredPackage) => void,
  additionalComponentPrices?: AdditionalComponentPrices,
  childComponentWasUpdated?: boolean,
  setChildWasUpdatedState?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [showSectionMoreInfo, setShowSectionMoreInfo] = React.useState<number>();

  const [packageIsBeingInteractedWith, setPackageIsBeingInteractedWith] =
    React.useState(false);

  const [childComponentUpdated, setChildComponentUpdated] = React.useState<boolean>(false);

  const packageIsBeingAdded =
    packageIsBeingInteractedWith && isLoading && packageSelectedValue;
  const packageIsBeingRemoved =
    packageIsBeingInteractedWith && isLoading && !packageSelectedValue;

  const packageIsSelected = !(
    (!packageSelectedValue &&
      !packageIsBeingInteractedWith &&
      !packageIsBeingRemoved) ||
    (packageSelectedValue &&
      (packageIsBeingAdded || packageIsBeingInteractedWith))
  );

  const canBeRemoved =
    question.customProperties?.changeControl?.canRemove !== false ||
    selectedInOriginalRisk === false;



  const packageInfo = React.useMemo<PackageInfo | null>(() => {
    if (!additionalComponentPrices) {
      return null;
    }

    const packageData = additionalComponentPrices[question.riskPath];

    if (packageData == null) {
      return null;
    }

    const resolvePackageLimit = (): string | null => {
      if (!packageData.packageLimit) {
        return null;
      }

      if (isString(packageData.packageLimit)) {
        return packageData.packageLimit;
      }

      return currencyToHumanReadable(packageData.packageLimit);
    };

    const resolvePackagePrice = () => {
      if (packageData.packagePrice === null || packageData.packagePrice.value === 0) {
        return null;
      }

      const prefix = packageSelectedValue
        ? packageData.packagePrice.value > 0
          ? '+'
          : '-'
        : '';

      const formattedPrice = currencyToHumanReadable({
        ...packageData.packagePrice,
        value: Math.abs(packageData.packagePrice.value)
      });

      return prefix + formattedPrice;
    };

    return {
      price: resolvePackagePrice(),
      limit: resolvePackageLimit()
    };
  }, [additionalComponentPrices, packageSelectedValue, question.riskPath]);

  const handlePackageClick = (packageSelected: boolean | ITieredPackage) => {
    onChange(packageSelected);
    setPackageIsBeingInteractedWith(true);
  };

  React.useEffect(() => {
    !isLoading &&
      packageIsBeingInteractedWith &&
      setPackageIsBeingInteractedWith(false);
  }, [isLoading]);

  React.useEffect(() => {
    childComponentWasUpdated && !childComponentUpdated && setChildComponentUpdated(true);

    if (!isLoading && childComponentUpdated) {
      setChildComponentUpdated(false);
      setChildWasUpdatedState && setChildWasUpdatedState(false);
    }
  }, [isLoading, childComponentWasUpdated]);

  return {
    showMoreInfo,
    setShowMoreInfo,
    showSectionMoreInfo,
    setShowSectionMoreInfo,
    canBeRemoved,
    packageLimit: packageInfo?.limit || null,
    packagePrice: packageInfo?.price || null,
    packageIsSelected,
    packageIsBeingInteractedWith,
    packageIsBeingAdded,
    packageIsBeingRemoved,
    handlePackageClick,
    childComponentUpdated
  };
};
